<template>
    <div class="filter-breadcrumb">
        <template  v-for="(item, index) in bCrumbs"  >
            <span :key="index" v-if="item.obj" class="item-breadcrumb" @click="openFilterByRef(item.bcRef)">
                {{ item.obj[item.field] }}
            </span>
        </template>
    </div>
</template>
<script>
export default {
    name: 'CBreadcrumbs',
    props: {
        bCrumbs: Array
    },
    methods: {
        openFilterByRef(refName) {
            this.$emit('openFilterByRef', refName);
        }
    }
};

</script>
<style scoped>

</style>
